import { useQueryClient } from "@tanstack/vue-query";
import { useLocalStorage } from "@vueuse/core";
import { getVersionQuery } from "../queries/getVersionQuery";

const lastVersion = useLocalStorage("lastAppVersion", '');

export const useHandlePwaUpdate = () => {
  const { $pwa } = useNuxtApp();
  const needRefresh = computed(() => !!$pwa?.needRefresh);
  const route = useRoute()
  const queryClient = useQueryClient()

  const updateApp = async () => {
    await $pwa?.updateServiceWorker(true); // Update with true to force the update
    window.location.reload();
  };

  const checkVersionAndUpdate = async () => {
    console.log('check version and update')
    try {
      // Get latest version ID
      const data = await queryClient.fetchQuery({
        ...getVersionQuery(),
      })

      // Update local storage if it's the first time
      if (!lastVersion.value) {
        lastVersion.value = data.version;
        return false;
      }

      // Don't update if the route is not '/'
      if (route.path !== '/') return false

      // Update if the version is different
      if (data.version !== lastVersion.value || needRefresh.value) {
        lastVersion.value = data.version;
        updateApp();
        return true;
      }
    } catch (e) {
      console.log(e);
    }

    return false;
  };

  watch(needRefresh, (value) => {
    console.log("PWA Update: needRefresh", value);
    if (value) {
      updateApp();
    }
  });

  return {
    checkVersionAndUpdate
  }
};
