import { queryOptions } from '@tanstack/vue-query'
import { useApi } from '~/domains/global/composables/useApi'
import type { IdeasMeta } from '~/domains/write/mutations/saveEssayMutation'

export const getEssayByIdQuery = (essayId: string, forWrite?: boolean) => {
  return queryOptions({
    queryKey: ['essay', essayId],
    queryFn: async () => {
      const api = useApi()
      const data = await api<Essay>('/essays/' + essayId + (forWrite ? '?forWrite=true' : ''), {
        method: 'GET',
      })

      return data
    },
    gcTime: 0,
    staleTime: 1000 * 60 * 60,
  })
}

export interface Essay {
  id: string
  title: string
  content: string
  themeId: string
  state: 'DRAFT' | 'IN_PROGRESS' | 'FINISHED'
  examType: 'ENEM'
  updatedAt: string
  createdAt: string
  userId: string,
  ideasMeta?: IdeasMeta
}
